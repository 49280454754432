.msearchView-open {
    overflow: hidden;
}
.msearchView{
    background: #fafafa;
    width: 100vw;
    height: 100vh;
    padding: 10px;
    padding-bottom: 0;
    /* width: 100%;
    height: 100%; */
    z-index: 50;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media only screen and (hover: none) and (pointer: coarse){
    .msearchView{
        height: calc(100 * var(--vh));
    }
}

.m-container{
    display: flex;
    position: relative;
}

.m-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.m-heading__title span{
    font-size: 23px;
    font-weight: 400;
    letter-spacing: .2rem;
    text-align: left
}
.m-select {
    margin-top: 20px;
}

@media only screen and (max-width: 360px) {
    .m-heading__title span{
        font-size: 20px;
        font-weight: 400;
        letter-spacing: .1rem;
    }
}