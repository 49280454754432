.Home{
    margin-top: 50px;
}

@media screen and (max-width: 600px) { 

    .HomeContentIcons {
        margin: auto;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .HomeContentIcons .Icon {
        cursor: pointer;
        text-align: center;
        width: 40%;
        border-bottom: 3px solid transparent;
        margin-bottom: 20px;
        margin: 0px 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HomeContentIcons h2 {
        min-width: none;
    }

    .HomeContentIcons .Icon img {
        width: 80%;
        height: auto;
        align-content: space-between;
    }
    .HomeContent {
        margin: auto;
        text-align: left;
        margin-top: 40px;
    }

    .HomeContent2 {
        width: 95%;
        margin-left: 5%;
    }

    .HomeContentText {
        margin: auto;
        width: 90%;
        text-align: center;
        margin-bottom: 50px;

    }

    .HomeContentText p {
        font-weight: 400;
        font-size: 15px;
    }

    .HomeContentText2 {
        margin: auto;
        width: 90%;
        text-align: justify;
        margin-bottom: 50px;
        padding-top: 20px;
        margin-top: 50px;
        border-top: solid 10px #E6D8CA;
    }

    .HomeContentText2 ol li {
        font-weight: 400;
        font-size: 15px;
        text-align: left;
    }

    .HomeContentText2 p {
        font-weight: 400;
        font-size: 15px;
    }
  
  }
  
  @media screen and (min-width: 601px) { 
  
    .HomeContentIcons {
        margin: auto;
        width: 60%;
        display: flex;
        justify-content: space-around;
    }

    .HomeContentIcons .Icon {
        cursor: pointer;
        text-align: center;
        width: 15%;
        border-bottom: 3px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
    }    

    .HomeContentIcons .Icon h2 {
        flex-grow: 2;
    }  

    .HomeContentIcons .Icon img {
        width: 100%;
        height: auto;
        align-content: space-between;
    }

    .HomeContent {
        margin: auto;
        text-align: left;
        margin-top: 50px;
    }

    .HomeContentText {
        margin: auto;
        width: 60%;
        text-align: center;
        margin-bottom: 50px;
    }

    .HomeContentText2 {
        margin: auto;
        width: 60%;
        text-align: justify;
        margin-bottom: 50px;
        padding-top: 20px;
        margin-top: 50px;
        border-top: solid 10px #E6D8CA;
    }

    .HomeContentText p {
        font-weight: 400;
        font-size: 17px;
    }

    .HomeContentText2 ol li {
        font-weight: 400;
        font-size: 17px;
        text-align: left;
    }

    .HomeContentText2 p {
        font-weight: 400;
        font-size: 17px;
    }

    .HomeContent2 {
        padding-left: 10%;
        margin-top: 70px;
    }
  

    .HomeContent2TextContainer {
        display: flex;
        width: 60%;
        margin: auto;
    }

    .HomeContent2TextContainer p {
        font-size: 17px;
        font-weight: 400;
    }

    .MiddleImageHome {
        width: 50%;
        margin-left: 30px;
    }

    .HomeContent2TextContainer .MiddleImageHome img {
        width: 100%;
    }
    
  }







.HomeContentIcons .Icon:hover {
    border-bottom: 3px solid green;
}



.HomeContentIcons h2 {
    text-align: center;
}




#DataLogo {
    height: 70px;
    width: auto;
}