.Searchbar {
  background-image: url("../containers/SearchBar/Header_images/heroimage.jpg");
  background-size: cover;
  height: 85vh;
  min-height: 450px;
  background-position: top;
  background-repeat: no-repeat;
  border-bottom: solid #db9d47 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Searchbar-content {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.54);
  padding: 0rem 1rem;
  text-align: left;
  margin-top: 47px;
  height: auto;
}

.SearchBar-fields,
.SearchBar-fields-DateAndHour,
.SearchBar-submit-container,
.InverseArrow,
.inputFrom,
.inputTo {
  display: flex;
  justify-content: center;
  align-self: center;
}



.SearchBar-fields {
  flex-direction: column;
}
.SearchBar-fields-DateAndHour,
.SearchBar-submit-container {
  flex-direction: row;
}

.Searchbar-content h2 {
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

@media screen and (max-width: 600px) {
  /*Screen with 600px or less, Mobiles*/
  .Searchbar .SearchBar-submit {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .InverseArrow {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .SearchBar-fields-DateAndHour {
    display: block;
    margin-top: 10px;
  }
  .SearchBar-fields-DateAndHour .StartArriveChoice, .SearchBar-fields-DateAndHour .DateAndTimePicker { 
    text-align: center;
  }
  .SearchBar-fields-DateAndHour .DateAndTimePicker {
    margin-top: 10px;
  }
  
  .SearchBar-fields input {
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets & Desktops: */
  .SearchBar-fields,
  .SearchBar-fields-DateAndHour,
  .SearchBar-submit-container {
    margin: 0rem 0rem;
  }
  .Searchbar {
    justify-content: flex-end;
  }
  .Searchbar-content {
    display: grid;
    width: auto;
    margin: 2rem;
    margin-bottom: 5rem;
    padding: 0.5rem 1rem 1rem 1rem;
    width: 75%;
  }
  .Searchbar-content h2 {
    grid-column: span 2;
    text-align: left;
    font-size: 1.7rem;
    margin-top: 5px;
  }
  .Searchbar .Searchbar-content .SearchBar-fields .inputFrom, .Searchbar .Searchbar-content .SearchBar-fields .inputTo {
    width: 35%;
  }
  .SearchBar-fields {
    grid-column: span 2;
    justify-content: flex-start;
    flex-direction: row;
  }
   .StartArriveChoice {
    margin-right: 10px;
  }
  .Searchbar .Searchbar-content .SearchBar-fields {
    margin-bottom: 18px;
  }
  .SearchBar-fields-DateAndHour {
    justify-content: flex-start;
    margin-left: 8px;
  }
  .SearchBar-submit-container {
    justify-content: flex-end;
  }
}

.InverseArrow {
  width: fit-content;
}

.InverseArrow img {
  position: relative;
  height: 2rem;
  cursor: pointer;
  display: block;
  margin: auto;
  margin: 0rem 15px 0 15px;
}
#To,
#From {
  width: 100%;
}
#To:focus, #From:focus {
  border-color: #592689;
}

#To:focus::placeholder, #From:focus::placeholder {
  color: transparent;
}

.MuiInputBase-input {
  font-weight: bold;
  color: grey;
}

#time-picker {
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: grey;
}

#time-picker:focus {
  box-shadow: none;
}

#time-picker:focus {
  box-shadow: none;
}

#StartArriveChoice {
  border: none;
  color: grey;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--select-border);
  cursor: pointer;
  background-color: #fff;
  font-size: 0.9rem;
}

#Date {
  display: inline-flex;
  background-image: url("https://logodix.com/logo/1891784.png");
  font-weight: bold;
  width: 70%;
  padding: 12px 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  border: 1.5px solid grey;
  border-radius: 4px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 40px;
  color: grey;
}

#Date:focus {
  border: 1.5px solid #699d51;
  outline-width: 0;
}

.SearchBar-submit {
  display: inline-block;
  background: #592689;
  color: whitesmoke;
  border-radius: 4px;
  border: #592689 solid 1px;
  cursor: pointer;
  vertical-align: middle;
  height: auto;
  padding: 7px 10px;
  text-align: center;
  font-weight: bold;
  font-family: "Baloo Paaji 2", "Helvetica", sans-serif;
  font-size: 1.08rem;
}

.SearchBar-submit:hover {
  background: #682ca0;
}

.SearchBar-submit a {
  color: whitesmoke;
}

.MuiButton-label {
  font-size: 0.8rem;
}
