.RegisterContainer {
    display: flex;
    margin: 67px auto 20px auto;
    width: 60%;
    position: relative;
}


.form-submit {
    cursor: pointer;
}

#signin {
    margin-top: 16px;
}

.signup-image-link {
    font-size: 14px;
    color: #222;
    display: block;
    text-align: center;
}

.signup-form a {
    text-decoration: none;
}

.signup-form a:hover {
    text-decoration: underline;
}


.form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
    display: flex;
}
.form-group:last-child {
    margin-bottom: 0px;
}

.registerImageContainer {
    padding-left: 40px;
}


.registerImageContainer img {
    width: 100%;
}



.signin-content {
    padding-top: 67px;
    padding-bottom: 87px;
}

.signin-formLittle {
    padding: 0px 10px 10px 10px;
}

.signin-contentLittle h2 {
    margin-block-start: unset;
    margin-bottom: 10px;
}

.signin-contentLittle p {
    margin-block-start: unset;
    margin-bottom: 5px;
}

.signin-formLittle .register-form .form-group {
    margin-bottom: 5px;
}

.signin-formLittle .register-form #signin {
    margin-top: 0px;
}

.signin-formLittle .register-form input {
    width: 100%;
}

.MuiPaper-root {
    margin-top: 13px;
}

.signin-image img {
 height: 200px;
}

@media screen and (max-width: 600px) { 
    .container {
        width: 100%;
    }

    .RegisterContainer{
        width: 90%;
    }

    .RegisterContainer input {
        width: 100%;
    }
    .PassandCountainer {
        width: 100%;
    }

    .registerImageContainer {
        display: none;
    }
}



@media screen and (min-width: 601px) { 

    .RegisterContainer{
        width: 60%;
    }

    .signup-content,
    .signin-content,
    .signin-contentLittle {
        flex-direction: column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .signup-form {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding: 0 30px;
    }

    .signin-image {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 50px;
        order: 2;
        -moz-order: 2;
        -webkit-order: 2;
        -o-order: 2;
        -ms-order: 2;
    }

    .signup-form,
    .signup-image,
    .signin-form,
    .signin-image {
        width: auto;
    }

    .social-login {
        justify-content: center;
        -webkit-justify-content: center;
    }

    .form-button {
        text-align: center;
    }

    .signin-form {
        order: 1;
        -moz-order: 1;
        -webkit-order: 1;
        -o-order: 1;
        -ms-order: 1;
        margin-right: 0px;
        margin-left: 0px;
        padding: 0 30px;
    }

    .form-title {
        text-align: center;
    }

    .signin-formLittle .form-title {
        text-align: left;
    }
}

#signup, #signin, .register-button, #changepassword {
        display:inline-block;
        background:#592689;
        color: whitesmoke;
        border-radius: 4px;
        border: #592689 solid 1px;
        cursor:pointer;
        vertical-align:middle;
        min-width: fit-content;
        width: 50%;
        height: auto;
        padding: 7px 10px;
        text-align: center;
        font-weight: bold;
        font-family: "Baloo Paaji 2","Helvetica", sans-serif;
        font-size: 13px;
}

#signup:hover, #signin:hover, .register-button:hover #changepassword:hover {
    background:#682CA0;
}

.register-form {
    margin-bottom: 10px;
}

.fb-btn,
.g-btn {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px !important;
    /* font-size: calc(0.27548vw + 12.71074px); */
    text-decoration: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    padding: 5px;
    height: 100%;
}
.d-flex {
    display: flex;
}

.showBtn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    display: none;
    user-select: none;
}


.PassStrenghtContainer {
    width: 100%;
    display: flex;
    place-content: space-between;
    margin-top: 3px;
}

.PassStrenghtLittleContainer1, .PassStrenghtLittleContainer2, .PassStrenghtLittleContainer3 {
    height: 10px;
    width: 30%;
    border-radius: 3px;
}

.Confirmation {
    margin: auto auto auto 5px;
    width: 15px;
}

.Confirmation img {
    height: 15px;
}

.social-label {
    font-weight: 600;
}

.GoogleLogin {
    margin-bottom: 15px;
}

.ChangePasswordContainer .signin-form {
    padding: 0;
}

.ChangePasswordContainer .form-group label {
    width: 150px;
    align-self: center;
}

.ChangePasswordContainer h2 {
    text-align: left;
}

.ChangePasswordContainer .signin-form .form-group {
    width: 40%;
    flex-wrap: wrap;
}
