@media screen and (max-width: 600px) { 

    .ChartInCalculationDetails {
      width: 100%;
    }
  
  }
  
  @media screen and (min-width: 601px) { 
  
    .ChartInCalculationDetails {
      width: 80%;
    }
  
  }


.ChartInCalculationDetails {
  height: 300px;
  margin: auto;
}