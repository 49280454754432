

.btn{
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    outline: none;
    font-size: inherit;

    display: inline-block;
    line-height: 21px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border-radius: 4px;
    height: auto;
    padding: 7px 20px;;
    text-align: center;
    font-weight: bold;
    font-family: "Baloo Paaji 2","Helvetica", sans-serif;
    font-size: 1rem;
    color: whitesmoke;
    background-color: #592689;
    transition: all .2s;

    position: relative;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

/* .btn:focus,.btn:active{
} */
.btn:hover{
    background-color: #682CA0;
}

.btn:disabled {
    background-color: rgb(89 38 137 / 62%);
  }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.loader{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}