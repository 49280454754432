.SearchbarLittle {
  justify-content: left;
  display: flex;

  width: 100%;
}

.SearchbarLittle .Searchbar-content-little {
  background-color: white;
  width: 100%;
  padding: 0.3% 1%;
  text-align: left;
  margin: auto 0;
  border-bottom: 1.7px black solid;
}


.SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .inputFrom, .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .inputTo {
  width: 25%;
}

.SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .inputTo {
  margin-right: 15px;
}

.SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .StartArriveChoiceLittle {
  margin-left: 10px;
  margin-right: 10px;
}

#StartArriveChoice-Little {
  border: none;
  color: grey;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--select-border);
  cursor: pointer;
  background-color: #fff;
  font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
  /*Screen with 600px or less, Mobiles*/
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .inputFrom, .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .inputTo {
    width: 80%;
  }
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little {
    text-align: -webkit-center;
  }
  .inputFrom, .inputTo {
    text-align-last: center;
    width: 90%;
  }
  .InverseArrowLittle {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .SearchbarLittle .SearchBar-submit {
    display: block;
    margin-top: 10px;
  }
  .MapAppSearchLittle {
    padding-top: 10px;
  }
}

@media (min-width: 601px) and (max-width: 779px) {
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .InverseArrowLittle {
    margin: auto 10px auto 10px;
  }
  .SearchbarLittle .SearchBar-submit {
    display: inline-block;
    margin-left: 20px;
  }
}

@media screen and (min-width: 780px) {
  /*Screen with 780px or so*/
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .InverseArrowLittle {
    margin: auto 10px auto 10px;
  }
  .SearchbarLittle .SearchBar-submit {
    display: inline-block;
    margin-left: 20px;
  }
}

@media (min-width: 781px) and (max-width: 790px) {
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }  
  .SearchbarLittle .Searchbar-content-little .SearchBar-fields-little .SearchBar-submit {
    margin-top: 10px;
  }
  .InverseArrowLittle {
    margin: auto 10px auto 10px;
  }
  .SearchbarLittle .SearchBar-submit {
    display: inline-block;
    margin-left: 20px;
  }
}


#DateLittle {
  display: inline-flex;
  width: 50%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 40px;
  color: grey;
}

#TimeLittle {
  display: inline-flex;
  width: 25%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 40px;
  color: grey;
}

#TimeLittle:focus {
  border: 1px solid #699d51;
  outline-width: 0;
}

.Searchbar-content-little .SearchBar-fields-little input:focus {
  border: 1px solid #699d51;
  outline-width: 0;
}

#time-picker:focus {
  border: none;
}

#time-picker-little {
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: grey;
}

.SearchbarLittle .SearchBar-submit {
  min-width: max-content;
  background: #592689;
  color: whitesmoke;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  width: fit-content;
  height: auto;
  padding: 7px 10px;
  text-align: center;
  font-family: "Baloo Paaji 2", "Helvetica", sans-serif;
  font-size: 13px;
}

.SearchBar-submit a {
  color: whitesmoke;
}

.SearchbarLittle .react-datepicker-wrapper {
  width: 20%;
}

.InverseArrowLittle {
  cursor: pointer;
}

.InverseArrowLittle img {
  height: 30px;
  vertical-align: middle;
}

.AlternativesHandler h4 {
  margin: 1px;
}
