.main_container{
    padding-bottom: 100px;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: relative;
}

.form{
    min-width: 405px;
    display: flex;
    flex-direction: column;
}

.form .error_message{
    margin-bottom: 50px;
}

.form .input{
    margin: 0 0 20px 0;
}
.form .input input{
    width: 100%;
    line-height: 21px;
    padding: 7px 20px;
    font-weight: bold;
    font-family: "Baloo Paaji 2","Helvetica", sans-serif;
    font-size: 1rem;
}


.form .bar_container {
    display: flex;
    justify-content: space-between;
    margin: 0 2px;
    margin-top: 10px;
}

.form .bar_container .bar{
    height: 10px;
    width: 30%;
    border-radius: 3px;
    background-color: rgb(105, 157, 81);
}
.form .bar:nth-child(2){
    background-color: rgb(89, 38, 137);
}
.form .bar:nth-child(3){
    background-color: rgb(44, 45, 64);
}