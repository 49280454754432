.ResultsContainer {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ResultsContainer::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
.ResultsContainer::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 10px;
}
.ResultsContainer::-webkit-scrollbar-thumb:hover {
  background: #cfcfcf;
}
.ResultsContainer::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 10px;
}

.loadingLogo {
  margin: auto;
  position: relative;
  right: auto;
  width: 30%;
}

.loadingLogo img {
  right: auto;
  position: relative;
}

@media only screen and (min-width: 600px) {
  /* For tablets & Desktops: */
  .ResultsContainer {
    height: 100%;
  }
}
