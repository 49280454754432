.d-container {
  display: block;
  margin: 10px 0 0px 0;
  width: 100%;
}
.container-booking {
  margin: 0 .8rem;
  margin-top: 15px;
  padding: 10px 0;
  border-top: 3px #909090 solid;
}
.container-booking a {
  text-decoration: none;
  color: #2c2d40;
}
.btn-container {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: red;
}
.btn__back,
.btn__back:visited,
.btn__back:link {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 10px;
  color: #592689;
  padding-left: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 4px;
  transition: all 0.2s;
  animation: backBtnEnter 0.5s;
}
a.btn__back:focus,
a.btn__back:focus-within {
  color: #592689;
}
a.btn__back{
  margin-left: 0.8rem;
  position: relative;
}

.btn__back:before {
  content: "";
  position: absolute;
  width: 0;
  height: 1.5px;
  bottom: 0;
  right: 0;
  background-color: #592689;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.btn__back:hover:before {
  visibility: visible;
  width: 100%;
}


@keyframes backBtnEnter {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.title {
  text-transform: uppercase;
  font-size: large;
  font-weight: 600;
  color: hsl(0, 0%, 20%);
  margin-top: 20px;
  margin-left: 0.8rem;
  animation: fadeInEnterFromRight 0.4s ease-in;
}

@keyframes fadeInEnterFromRight {
  0% {
    opacity: 0;
    transform: translateX(5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.main-path-container {
  margin-top: 15px;
}

.path-detailed-container {
  display: flex;
  animation: fadeInEnterFromRight 0.4s ease-in-out;
  padding: 0 0 0 .8rem;
}
.path-detailed-container:last-child {
  margin-bottom: 2rem;
}
.path-detailed-container:not(:last-child) {
  margin-bottom: 10px;
}
.cost {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-end;
  width: 25%;
  cursor: pointer;
}
.cost img {
  height: 35px;
}
.cost p {
  text-align: -webkit-center;
  margin-block-end: 3px;
}
.path-card {
  width: 75%;
  min-width: 75%;
  max-width: 75%;
  border: 1.7px solid #592689;
  border-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0;
}

.path-card:hover {
  border: 1.7px solid #592689;
  box-shadow: 0px 0px 2px 2px #909090;
  cursor: pointer;
}
.path-card--active {
  border: 1.7px solid #592689;
  box-shadow: 0px 0px 2px 2px #909090;
}

.path-card--detailsSelected {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.path-card__heading-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0.8rem;
  padding-top: 5px;
}
.path-card__route-textAndIcon {
  align-content: flex-start;
}
.path-card__small-text {
  text-align: left;
  padding-left: 2.5px;
  font-style: italic;
  margin: 0 0 5px 0;
}
.path-card__transport-icon {
  padding-top: 10px;
  margin: auto 0;
  text-align: left;
}
.path-card__transport-icon img {
  position: relative;
  margin: auto 2px;
}
.path-card__co2Container {
  margin: 0;
  min-width: max-content;
  text-align: right;
  flex: 1;
}
.path-card__co2-text {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #699d51;
}
.path-card__co2-text:hover{
  text-decoration: underline;
}
.path-card__row{
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  padding: 0 0.8rem;
  border-bottom: 0px solid #592689;
}
.path-card__DropDownBtn {
  padding-left: 8px;
  padding-right: 0;
}
.path-card__time-container {
  display: flex;
  align-items: center;
}
.path-card__time-container span {
  font-size: 16px;
  font-weight: 500;
}
.detailed_section {
  padding: 0 0.8rem;
  overflow: hidden;
  animation: TopToBottomFadeIn 0.4s ease-in-out;
}

.arrow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}
.arrow .icon {
  width: 100%;
  transform: scale(80%);
}

.rotate180 {
  transform: rotate(180deg);
  transition: all 0.2s;
}

.rotateBack {
  transform: rotate(0deg);
  transition: all 0.2s;
}

@keyframes TopToBottomFadeIn {
  0% {
    height: 0;
  }
  20% {
    height: 20%;
  }
  30% {
    height: 30%;
  }
  40% {
    height: 40%;
  }
  50% {
    height: 50%;
  }
  60% {
    height: 60%;
  }
  70% {
    height: 70%;
  }
  80% {
    height: 80%;
  }
  90% {
    height: 90%;
  }
  100% {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .container-booking {
    width: 95%;
  }
  .path-detailed-container {
    padding: 0 .8rem;
  }
  .path-card {
    min-width: 100%;
  }
  .cost {
    display: none;
  }
}