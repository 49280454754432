* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 17px;
  }

  h3 {
    font-size: 15px;
  }

  p {
    font-size: 15px;
  }

input {
  font-size: 13px;
  font-weight: 600;
  border: 1.5px solid grey;
  border-radius: 4px;
  background-color: white;
  color: grey;
  padding: 7px;
}

input:focus {
outline: none;
box-shadow: 1px -2px 10px -1px rgba(145,145,145,0.43);
-webkit-box-shadow: 1px -2px 10px -1px rgba(145,145,145,0.43);
-moz-box-shadow: 1px -2px 10px -1px rgba(145,145,145,0.43);
}

/* Login/Registration confirmation modal --start */
.modal-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 4rem 3rem 4rem;
}
.modal-content .main-text{
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.9px;
}
.modal-content .main-text span{
  display: block;
}
.modal-content .main-text div{
  font-weight: normal;
  display: flex;
  justify-content: space-between;
}
.modal-content .main-text div p{
  font-size: 20px;
}

.modal-content .otp-container{
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.modal-content .btn-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .modal-content{
      padding: 4rem 2rem;
  }
}
/* Login/Registration confirmation modal --end */


::selection {
  background: #592689;
  color: #fff;
}