@media screen and (max-width: 600px) {
  header .Header-Container {
    display: inline-flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 47px;
    width: 100vw;
    margin: auto;
  }
  .Select-Language-Container {
    margin-right: 10px;
  }
  header img {
    margin-right: 20px;
  }
  .UserImgHeader {
    margin: auto 5px auto 0px;
  }

  .HeadBarLink {
    margin: auto 5px auto 12px;
  }
}

.custom-select {
  outline: none;
  border: 1px solid var(--select-border);
  cursor: pointer;
  background-color: #fff;
}

@media screen and (min-width: 601px) {
  header .Header-Container {
    display: inline-flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 47px;
    width: 60%;
    margin: auto auto auto 20%;
  }

  .UserImgHeader {
    margin: auto 10px auto 0px;
  }

  .HeadBarLink {
    margin: auto 10px auto 12px;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  height: 47px;

  background-color: white;
  box-shadow: 0px 15px 10px -15px #111;
  font-weight: 700;
  color: #2c2d40;
}

header img {
  height: 30px;
  width: auto;
  cursor: pointer;
  margin-right: 5px;
  box-sizing: unset;
}

.LogoAndHeadbar {
  display: flex;
}

.HeadBarLink:hover {
  color: #699d51;
}

.LoginHeadBar {
  display: flex;
}

.UserImgHeader {
  border-radius: 100%;
  height: 30px;
}

.UserImgHeaderIncognito {
  border-radius: 100%;
  border: 2px solid;
  height: 20px;
  margin-right: 10px;
}

@media only screen and (min-width: 600px) {
  /* For tablets & Desktops: */
  .DropDownUser {
    margin-left: 10px;
  }
}


.SelectLanguageAndUser {
  display: inline-flex;
  align-items: center;
}

.Select-Language-Container {
  margin-right: 0px;
}
.HeadBarLink {
  margin: auto 05px auto 0px;
}
.MuiButton-text {
  padding: 6px !important;
}
