.regularModal {
    height: auto !important;
    border-radius: 10px;
    background-color: white !important;
    box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 54%);
}

.regularModal .container {
    /* margin-top: 3.0rem; */
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    ;
}
.regularModal .container .title{
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.regularModal .container .input-container {
    width: 95%;
    display: block;
    margin: 0 auto;
}
.regularModal .container .input-container label {
    margin-right: 10px;
    font-size: 1rem;
}
.regularModal .container .input-container .year-picker {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 10px;
}
/* .regularModal .container .input-container .year-picker {
    margin-right: 10px;
    font-size: 1rem;
} */

.regularModal .container .input-container .month-slider {
    display: flex;
    margin-top: 10px;
}
.regularModal .container .input-container .month-slider .slider{
    width: 100%;
}
.regularModal .container .input-container .input-controller {
    margin-top: 10px;
    /* display: flex;
    align-items: center; */
}
.regularModal .container .input-container .input-controller .input-select{
    width: 223px;
}
.regularModal .container .input-container .input-controller :global .MuiFormControl-marginNormal {
    margin-top: 0px;
}
.regularModal .container .input-container .input-controller  :global #time-picker{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}
.regularModal .container .input-container .fromToInput {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.regularModal .container .input-container .fromToInput :global .inputFrom,
.regularModal .container .input-container .fromToInput  :global .inputTo {
    width: 100%
}

.regularModal .container .input-container .bottom-btn {
    margin-top: 20px;
}
/* .regularModal .container .input-container .journey-status-picker {
    margin-top: 10px;
}
.regularModal .container .input-container .journey-status-picker .input-select{
    width: 223px;
} */

@media only screen and (max-width: 992px) {
    .regularModal .container .input-container {
        width: 100%;
        flex-direction: column;
    }
    .regularModal .container .input-container .year-picker :global .MuiFormControl-marginNormal,
    .regularModal .container .input-container .year-picker :global .MuiDateRangePickerInput-root{
        margin-left: auto;
    }
    .regularModal .container .input-container .fromToInput,
    .regularModal .container .input-container .month-slider {
        flex-direction: column;
    }
    .regularModal .container .input-container .input-controller {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    
}