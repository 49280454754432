
@media screen and (max-width: 600px) { 

  .UserContainer {
    width: 90%;
  }

}

@media screen and (min-width: 601px) { 

  .UserContainer {
    width: 60%;
  }

}


.UserContainer {
    position: relative;
    margin: 70px auto 0 auto;
    font-size: 15px;
}

.UserBannerCountainer {
    display: inline-flex;
    border-bottom: 2px grey solid;
    width: 100%;
    padding-bottom: 10px;
}

.UserImg {
    height: 150px;
    border-radius: 50%;
    border: #592689 solid 3px;
    margin-right: 30px;
    margin-left: 30px;
    padding: 10px;
}

.UserName {
    margin: auto auto auto 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
}

.UserHeadbarCountainer {
    display: inline-flex;
    width: 100%;
    align-content: center;
}

.UserHeadbarCountainer h3 {
    margin: 10px;
    border-bottom: transparent 2px solid;
}

.UserHeadbarCountainer h3:hover {
    border-bottom: #699D51 2px solid;
}

.UserHeadbarCountainer2 {
    display: inline-flex;
    width: 100%;
    align-content: center;
}

.Balance {
    padding: 10px
}

.Journeys {
    padding: 10px
}

.Compensate {
    padding: 10px
}

.Journeys-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Journeys-table-body {
    background: rgb(219, 233, 250) none repeat scroll 0 0;
  }
  
  .Journeys-table-head-item {
    padding: 6px 12px;
    width: 20%;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, .38);
    text-align: right;
    text-transform: uppercase;
  }
  
  .Journeys-table-row:nth-child(even) {
    background: rgb(236, 243, 252) none repeat scroll 0 0;
  }
  
  .Journeys-table-item {
    overflow: hidden;
    padding: 12px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    text-overflow: ellipsis;
  }
  
  .Journeys-table-head-item:not(:first-child):not(:last-child),
  .Journeys-table-item:not(:first-child):not(:last-child) {
    text-align: left;
  }
  
  .Journeys-table-item:first-child {
    width: 5%;
  }
  
  .Journeys-table-item:nth-child(2) {
    width: 30%;
  }
  
  .Journeys-table-item:nth-child(3) {
    width: 25%;
  }
  
  .Journeys-table-item:nth-child(4) {
    width: 10%;
  }
  
  .Journeys-table-item:nth-child(5) {
    width: 10%;
  }
  
  .Journeys-table-item:last-child {
    width: 10%;
    min-width: 100px;
  }
  
  .Journeys-table-row--active {
    font-size: 30px;
    color: #fff;
    background: #4990e2;
  }