.MapApp {
  display: grid;
  grid-template-rows: 47px max-content calc(100vh - 105px);
  grid-column: 1fr 1fr;
}
.MapAppSearchLittle {
  margin: 7px 0px;
  grid-row: 2/3;
  grid-column: 1/-1;
}
.ResultsContainer::-webkit-scrollbar {
  all: unset;
}
.MapAndResults {
  grid-row: 3/4;
  grid-column: 1/-1;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2.5fr 2fr;
  background: white;
  font-size: 0.8rem;
  border-top: 2px black solid;
}
.AddPathToBallance {
  grid-row: 3/4;
  grid-column: 2/-1;
  z-index: 10;
  justify-self: end;
  margin: 0.6rem;
}

.MapAndResults p {
  font-size: 0.8rem;
}

.Searchbar-content-little {
  border: none !important;
}

.ResultsContainer {
  grid-row: 1/2;
  grid-column: 1/2;
}
.Map {
  height: 100%;
  grid-row: 1/2;
  grid-column: 2/3;
}

@media screen and (max-width: 600px) {
  /*Screen with 600px or less, Mobiles*/
  *::-webkit-scrollbar {
    display: none;
  }
  .MapAndResults {
    grid-template-rows: 30vh auto;
    grid-template-columns: 1fr;
  }
  .ResultsContainer {
    grid-row: 2/3;

    grid-column: 1/2;
  }
  .AddPathToBallance {
    justify-self: start;
  }

  .MapApp {
    grid-template-rows: 47px max-content max-content;
  }
  .MapAndResults {
    height: calc(100vh - 50px);
  }
  .Map {
    border: none;
    grid-row: 1/2;

    grid-column: 1/2;
  }
  .Searchbar-content-little .inputFrom, .Searchbar-content-little .inputFrom {
    width: 60%;
  }
}

@media screen and (min-width: 963px) {
  /*Screen with 1000px or so*/
  .AddPathToBallance {
    grid-row: 3/4;
    grid-column: 2/-1;
    z-index: 1;
  }
  .MapAndResults {
    grid-template-columns: 2fr 3fr;
  }
  .MapApp {
    grid-template-rows: 47px max-content calc(100vh - 105px);
  }
  .SearchbarLittle .SearchBar-submit {
    padding-top: 0;
  }
}

@media screen and (max-width: 420px) {
  .MapApp {
    grid-template-rows: 47px max-content calc(100vh - 140px - 47px - 35px);
  }
}



@media (min-width: 781px) and (max-width: 790px) {
  .MapApp {
    grid-template-rows: 47px max-content calc(100vh - 145px)
  }
}

@media (min-width: 600px) and (max-width: 780px) {
  .Searchbar-content-little .SearchBar-submit {
    margin-top: 10px;
  }
  .MapApp {
    grid-template-rows: 47px max-content calc(100vh - 143px)
  }
}
