.form_container {
    border: 2px solid rebeccapurple;
    max-width: 100%;
    min-width: fit-content;
}

.form_container .input_container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
}


.form_container .input_container .avtar_container{
    display: flex;
    flex-wrap: wrap;
}

.form_container .input_container .avtar_container img{
    height: 80px;
    margin: 3px;
    padding: 3px;
    border-radius: 100%;
    border: 2px solid transparent;
}

.form_container .saveBtnCtn{
    display: flex;
    flex-direction: row-reverse;
    margin: 20px 20px;
}

.btn_wraper{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
}

.btn_container{
    display: flex;
    flex-direction: column;
}

.btn_container .btn_style{
    margin: 10px 0;
}

@media screen and (max-width: 600px) { 

    .PrimaryInput_input {
        max-width: 80%;
    }


}


@media screen and (min-width: 601px) { 

    .form_container {
        padding: 0 25px;
    }


}