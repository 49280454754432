.AddPathToBallance {
  display: grid;
  place-content: center;
  background: #f8f8f8;
  padding: 10px;
  text-align: center;
  border: #592689 2px solid;
  border-radius: 4px;
  height: fit-content;
  justify-items: center;
}

.AddPathToBallance p {
  font-size: 0.8rem;
  margin: 0;
}

.AddPathToBallanceFromTo {
  font-weight: 600;
}

.AddPathToBallanceCO2 {
  font-weight: 600;
  color: #699d51;
}

.AddPathToBallance .register-button {
  width: auto;
  margin-top: 10px;
}


@media screen and (max-width: 600px) { 

  .AddPathToBallance {
    width: 25%;
    min-width: 80px;
  }

  .AddPathToBallanceFromTo {
    display: none;
  }

}

@media screen and (min-width: 601px) { 

  .AddPathToBallance {
    width: 120px;
  }

}