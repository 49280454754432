
@media screen and (max-width: 600px) { 

    .Pages {
      width: 90%;
      margin: 67px auto 20px auto;
    }
    .TransportFlexContainer {
      align-items: center;
    }
    .TransportTextContainer {
      width: 100%;
    }
    .TransportDataContainer {
      font-size: 60px;
      text-align: center;
    }
  
  }
  
  @media screen and (min-width: 601px) { 
  
    .Pages {
      width: 60%;
      margin: 67px auto 20px auto;
    }
    .TransportFlexContainer {
      display: flex;
      align-items: center;
    }
    .TransportTextContainer {
      width: 70%;
    }
    .TransportDataContainer {
      width: 30%;
      font-size: 60px;
      text-align: center;
      margin-right: 50px;
    }
  }

  .Pages .PagesImage {
    width: 100%;
    margin-bottom: 20px;
}  

  .Pages .PagesImage img {
    width: 100%; 
}

.Pages {
  text-align: justify;
}