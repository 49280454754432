

    .FooterContainer {
        padding: 10px 5% 10px 5%;
    }
  



.FooterContainer {
    background-color: #D8C4B0;
    height: 100px;
    margin-top: 80px;
}

.Footer {
    display: flex;
    justify-content: space-between;
}

.Footer .FooterLinks {
    display: inline-grid
}

.Footer .FooterLinks span {
    cursor: pointer;
}

.Footer .FooterLinks span:hover {
    font-weight: 600;
}

.Copyrigth {
    align-self: flex-end;
    line-height: 0;
}
