.searchModal {
    height: auto !important;
    border-radius: 10px;
    background-color: white !important;
    box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 54%);
}

.searchModal .container {
    /* margin-top: 3.0rem; */
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    ;
}
.searchModal .container .title{
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.searchModal .container .input-container {
    width: 80%;
    display: flex;
    flex-direction: row;
}

.searchModal .container .input-container :global .inputFrom,
.searchModal .container .input-container :global .inputTo {
    width: 100%;
}

.searchModal .container :global .SearchBar-fields-DateAndHour,
.searchModal .container :global .SearchBar-submit-container {
    margin-top: 1rem;
}

@media only screen and (max-width: 992px) {
    .searchModal .container .input-container {
        width: 100%;
        flex-direction: column;
    }
}