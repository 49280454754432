@media screen and (max-width: 600px) { 
    .PrivacyPolicy {
        width: 90%;
    }  
}
  
@media screen and (min-width: 601px) {
    .PrivacyPolicy {
        width: 60%;
    }   
 
}

.PrivacyPolicy {
    margin: 70px auto 0 auto;
    padding: 10px;
}

.PrivacyPolicy p {
    text-align: justify;
}

.PrivacyPolicy ol {
    font-size: 0.95rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.PrivacyPolicy ul {
    font-size: 0.95rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

