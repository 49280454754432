.banner-container{
  width: 75%;
  background: #562782;
  padding: 1px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 2px solid #592689;
}
.banner-container h2{
  text-align: center;
  color: #fff;
}

.PathListAndLoading {
  width: 100%;
}

.PathList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: 10px;
}

.FlipMove {
  position: relative;
  width: 100%;
}

.PathAndAlternatives {
  width: 100%;
  margin-bottom: 10px;
}

.PathAndAlternatives h2 {
  text-align: left;
}

.Path {
  width: 100%;
  display: inline-flex;
}

.Path p {
  margin: 0;
}

.PathMain {
  background: white;
  border: solid #2c2d40 1.7px;
  padding-top: 0.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 5px;
  z-index: 0;
  position: relative;
  text-align: left;
}

.PathMain:hover {
  border: 1.7px solid #592689;
  box-shadow: 0px 0px 2px 2px #909090;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  cursor: pointer;
}


/* Active Path */

.ActivePath p {
  margin: 0;
}

.ActivePath {
  width: 100%;
  display: inline-flex;
}

.ActivePath .PathMain {
  background: white;
  border: 1.7px solid #592689;
  box-shadow: 0px 0px 2px 2px #909090;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 75%;
  padding-top: 0.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  min-width: 75%;
  max-width: 75%;
}

.ActivePath .PathMain:hover {
  border: 1.72px solid #592689;
  box-shadow: 0px 0px 2px 2px #909090;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  cursor: pointer;
}

.IconAndTypeAndCO2 {
  display: flex;
  justify-content: space-between;
}

.IconAndType {
  align-content: flex-start;
}

.RouteType {
  text-align: left;
  padding-left: 2.5px;
  font-style: italic;
  margin: 0 0 5px 0;
}

.Duration {
  text-align: left;
  font-size: 1rem;
}

.DurationBatteryRefueling {
  text-align: left;
  font-size: 0.8rem;
}

.Icon {
  margin: auto 0;
  text-align: left;
}

.Icon img {
  position: relative;
  margin: auto 2px;
}

.TransportType {
  min-width: max-content;
  border-bottom: grey 1.5px solid;
  margin: 5px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TransportType h2 {
  margin-block: 0px;
}

.CO2 {
  margin: 0;
  min-width: max-content;
  text-align: right;
}

.CO2 p span {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #699d51;
}

.CO2 p span:hover {
  text-decoration: underline;
}

.percentCO2 {
  margin: 0;
  font-size: 1.1rem;
  color: #592689;
  font-weight: 600;
}

.tooltipCarbonCredit {
  position: relative;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  margin-left: 2px;
}

.tooltipCarbonCredit .tooltipCarbonCreditText {
  visibility: hidden;
  width: 300px;
  background-color: #2c2d40;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 0.75rem;
  font-weight: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: calc(-1.5rem - 5px);
  left: 145%;
}

.tooltipCarbonCredit .tooltipCarbonCreditText::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltipCarbonCredit:hover .tooltipCarbonCreditText {
  visibility: visible;
}

.DurationAndStartAt {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 0em;
  padding-left: 2.5px;
}

.FromTo {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2rem;
  margin: auto;
  font-size: 0.85rem;
}

.FromTo_From {
  width: 45%;
  padding: 0 10px;
}

.FromTo_To {
  width: 45%;
  padding: 0 10px;
}

.FromTo_Arrow {
  width: 10%;
}

.FromTo_Arrow img {
  width: 60%;
  height: auto;
  position: relative;
}

.FromTo p {
  margin-top: 0;
  margin-bottom: 0;
}

/* Path Second */

.PathSecond {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.3rem;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 10px;
}

.BookButton {
  border: 2px solid #2c2d40;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  padding: 1px;
  color: #2c2d40;
  width: 70%;
  min-width: fit-content;
}

.BookButton:hover {
  border: 2px solid #2c2d40;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  padding: 1px;
  margin: 0.5rem 1.5rem 0.2rem 1.5rem;
  color: #2c2d40;
  width: 70%;
  min-width: fit-content;
}

.Price {
  text-align: center;
  font-size: 0.8rem;
}

.TotalPrice {
  font-weight: 600;
}

/* More details Countainer */


.DetailsContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: block;
}

.DetailsContainerHidden {
  display: none;
}


.TimeStep {
  color: #2c2d40;
  font-weight: 600;
}


.NumberOfPassengersHandler {
  display: flex;
  align-items: center;
}

.NumberOfPassengersHandler button {
  border: 1.1px solid #699d51;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0px;
  justify-content: center;
}

.NumberOfPassengersHandler button:focus {
  outline: none;
}

.switchToElectricCountainer {
  display: inline-flex;
  text-align: left;
  margin-top: 10px;
}

.switchToElectric {
  position: relative;
  display: block;
  width: 30px;
  height: 17px;
  margin-left: 4px;
  margin-right: 5px;
}

.switchToElectric input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #592689;
}

input:focus + .slider {
  box-shadow: 0 0 1px #592689;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#currentIcon {
  position: relative;
  height: 18px;
  width: auto;
  margin-right: 5px;
}

.BigLoadingLogoContainer {
  width: 100%;
}

.BigLoadingLogo {
  width: 25%;
  margin: auto;
}

.BigLoadingLogo img {
  height: 100%;
  width: 100%;
}

.LittleLoadingLogo {
  width: 10%;
  margin: auto;
}

.LittleLoadingLogo img {
  height: 100%;
  width: 100%;
}

.RideShareButton {
  padding: 5px;
  text-align: center;
  font-size: 0.8rem;
}

.RideShareButton img {
  height: 20px;
}

.RideShareButton a {
  text-decoration: none;
  color: #2c2d40;
}

.TotalPrice img {
  height: 35px;
}

.percentCO2 table tr td {
  padding: 0;
  text-align: right;
  font-size: 0.8rem;
  vertical-align: middle;
}

/* ICONS */

.CarIcon {
  width: 45px;
  height: auto;
}

.PlaneIcon {
  width: 50px;
  height: auto;
}

.TrainIcon {
  width: 30px;
  height: auto;
}

.SubwayIcon {
  width: 35px;
  height: auto;
}

.TramIcon {
  width: 30px;
  height: auto;
}

.CitybusIcon {
  width: 42px;
  height: auto;
}

.TaxiIcon {
  width: 30px;
  height: auto;
}

.BusIcon {
  width: 37px;
  height: auto;
}

.FerryIcon {
  width: 37px;
  height: auto;
}

.InclinedIcon {
  width: 35px;
  height: auto;
}

.BicyleIcon {
  width: 60px;
  height: auto;
}

.ChartIcon {
  height: auto;
  transform: translateY(-100%);
  padding-bottom: 2px;
}


@media screen and (max-width: 600px) { 

  .LittleLoadingLogoContainer {
    width: 100%;
  }


  .PathSecond {
    display: none;
  }

  .PathMain, .ActivePath .PathMain, .ActivePath {
    width: 100%;
    max-width: none
  }

  .PathList {
    margin-right: 10px;
  }

}

@media screen and (min-width: 601px) { 

  .LittleLoadingLogoContainer {
    width: 75%;
    margin-left: 0;
  }

  .PathMain {
    width: 75%;
    min-width: 75%;
    max-width: 75%;
  }
  
  .PathMain:hover {
    width: 75%;
    min-width: 75%;
    max-width: 75%;
  }

  .ActivePath .PathMain {
    width: 75%;
    min-width: 75%;
    max-width: 75%;
  }

  .FrameForMobile {
    visibility: hidden;
  }


}

.ImgPathDetails img {
  width: 20px;
}


.ClickableLink .ClickableLinkImage {
  height: 15px;
  margin-bottom: 7.5px;
}

.SeeOptionsHandler {
  cursor: pointer;
  width: 90%;
  text-align: -webkit-center;
  padding: 7px 10px;
  background-color: #592689;
  border-radius: 5px;
  color: whitesmoke;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 10px;
}

.SeeOptionsHandler:hover {
  background: #682ca0;
}

.SeeOptionsHandlerMobile {
  cursor: pointer;
  width: fit-content;
  padding: 2px 5px;
  border-top-left-radius: 5px;
  background-color: #592689;
  color: whitesmoke;
  position: absolute;
  right: 0;
  bottom: 0;
}

.SeeOptionsHandlerMobile h4 {
  margin: 1px;
}