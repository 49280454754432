.OTPInput[type=number]::-webkit-inner-spin-button, 
.OTPInput[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.OTPInput{
    text-align: center;
    font-size: 1.4rem;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 60px;
    height: 40px;
    border: 0;
    caret-color: rebeccapurple;
    color: rebeccapurple;
    border-bottom: 1px solid grey;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out;
     /* Firefox */
    -moz-appearance: textfield;
}
.OTPInput:focus, .OTPInput:active{
    border-bottom: 2px solid grey;
    box-shadow: 1px 4px 9px 0px rgba(102,51,153,0.43)
}

@media only screen and (max-width: 600px) {
  .OTPInput{
      width: 40px;
  }
}