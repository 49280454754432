.modal-open {
    overflow: hidden;
}
.modalContainer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modalContainer .modal{
    height: auto;
    width: 850px;
    background-color: rgb(248, 248, 248);
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalContainer .closeBtn {
    position: absolute;
    left: 100%;
    transform: translateX(-100%);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 2rem;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .modalContainer .modal{
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .modalContainer .modal{
        width: 95%;
    }
}

