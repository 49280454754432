  .meter {
    box-sizing: content-box;
    height: 20px; /* Can be anything */
    position: relative;
    margin: 30px auto;
    border-radius: 25px;
    padding: 4px;
    box-shadow: 0px 0px 2px 4px lightgrey;
  }
  .meter > span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #699D51;
    background-image: #699D51;
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
  }
  .meter > span:after,
  .animate > span > span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
  
  .animate > span:after {
    display: none;
  }
  
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }

@media screen and (max-width: 1000px) { 

    .PieChartAndText {
        display: block;
    }

    .TextBeforePie {
        width: auto;
    }

    .PieChart1 {
        width: 100%;
        height: 250px;
        margin: 60px auto 60px auto;
    }

    .PieChartAndText ol {
        -webkit-columns: 2;
           -moz-columns: 2;
                columns: 2;
        padding-left: 0;
    }

    .PieChartAndText  ol li {
        list-style-position: inside;
        -webkit-column-break-inside: avoid;
                  page-break-inside: avoid;
                       break-inside: avoid;
    }

    .TwoPieCharts {
        display: block;
        height: 400px;
        text-align: center;
    }

    .PieChart2, .PieChart3 {
        width: 100%;
        margin: 10px auto 10px auto;
    }

    .PieChart2 h5, .PieChart3 h5 {
        margin: 0;
    }

}

.PieChartAndText .btn-container{
    width: auto;
}
.PieChartAndText .btn-container button{
    width: 90%;
    margin: 10px 10px 10px 0;
}

@media screen and (min-width: 1001px) { 

    .PieChartAndText {
        display: flex;
    }

    .TextBeforePie {
        width: 30%;
    }

    .TextBeforePie ol {
        font-size: 1.85vmin;
    }

    .PieChart1 {
        width: 70%;
        height: 300px;
    }

    
    .TwoPieCharts {
        display: flex;
        height: 200px;
        text-align: center;
    }

    .PieChart2, .PieChart3 {
        width: 50%;
    }

    .PieChart2 {
        padding-right: 10px;
        height: fit-content;
        border-right: lightgrey 3px solid;
    }
    .PieChart3 {
        margin-left: 10px;
        height: fit-content;
    }

}
/*  this is just demo commit */